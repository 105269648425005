<template>
  <div class="equipment">
    <PageTopNav
      :tabsShow3="true"
      @changeTab3="changeTab3"
      :tabList3="tabList3"
      :changeIndex3="changeIndex3"
    >
    </PageTopNav>
    <router-view></router-view>
    <MyNav></MyNav>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTopNav: () => import("@/components/PageTopNav.vue"),
  },
  data() {
    return {
      tabList3: {},
      changeIndex3: 0,
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getEquipmentGroupApi();
  },

  methods: {
    ...mapMutations("user", ["SET_EQUIPMENT_Name"]),
    getEquipmentGroupApi() {
      this.tabList3 = {
        0: {
          cid: 1,
          id: 1,
          icon: "processPlan",
          name: "生产计划",
          children: [],
        },
        1: {
          cid: 1,
          id: 2,
          icon: "processList",
          name: "生产记录",
          children: [],
        },
        2: {
          cid: 1,
          id: 3,
          icon: "processStatistics",
          name: "生产统计",
          children: [],
        },
      };

      this.changeIndex3 = this.tabList3["0"].id;
    },
    changeTab3({ id, icon }) {
      if (id) {
        this.changeIndex3 = id;
      }
      switch (icon) {
        case "processPlan":
          this.$router.push({
            name: "processPlan",
          });
          break;
        case "processStatistics":
          this.$router.push({
            name: "processStatistics",
          });
          break;
        case "processList":
          this.$router.push({
            name: "processList",
          });
          break;
        case "":
          this.$router.push({
            name: "processList",
          });
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.equipment {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 0 0;
  color: #fff;
  overflow: hidden;
}
</style>
